import request from "@/utils/request";
//原始支付
export function queryBatchCheck(page = 1, size = 20, data = {}) {
	return request({
		//   url: "/sys/orderDetail/queryBatchCheck?workDate="+data,
		url: "/sys/orderDetail/queryBatchCheckNew?page=" + page + "&size=" + size,
		method: "post",
		data
	});
}
export function queryBatchCheckTotal(page = 1, size = 20, data = {}) {
	return request({
		url: "/sys/orderDetail/queryBatchCheckTotal?page=" + page + "&size=" + size,
		method: "post",
		data
	});
}
//备用支付
export function queryWechatBatchCheck(page = 1, size = 20, data = {}) {
	return request({
		//   url: "/sys/orderDetail/queryBatchCheck?workDate="+data,
		url: "/sys/wechatDetail/queryBatchCheck?page=" + page + "&size=" + size,
		method: "post",
		data
	});
}
export function queryWechatBatchCheckTotal(page = 1, size = 20, data = {}) {
	return request({
		url: "/sys/wechatDetail/queryBatchCheckTotal?page=" + page + "&size=" + size,
		method: "post",
		data
	});
}